<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="View Reimburse" :active="isActive" v-on:update:active="emitModalIsActive">

      <div :class="{'opacity-50 pointer-events-none': isLoadingGetInitData}">
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Nomor</label>
            <vs-input class="w-full" :value="data.no_reimburse" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Proyek</label>
            <vs-input class="w-full" :value="data.nama_proyek" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Staf</label>
            <vs-input class="w-full" :value="data.nama_staf" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Tanggal</label>
            <vs-input class="w-full" :value="data.tgl_reimburse" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Judul Reimburse</label>
            <vs-input class="w-full" :value="data.judul_reimburse" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Keterangan</label>
            <vs-input class="w-full" :value="data.keterangan" readonly/>
          </div>
        </div>

        <vs-divider> Detail Transaksi </vs-divider>

        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <vs-table :data="data.details" stripe>
              <template slot="thead">
                <vs-th class="whitespace-no-wrap">Item Reimburse</vs-th>
                <vs-th class="whitespace-no-wrap">No. Nota</vs-th>
                <vs-th class="whitespace-no-wrap">Tgl. Nota</vs-th>
                <vs-th class="whitespace-no-wrap">Bukti Nota</vs-th>
                <vs-th class="whitespace-no-wrap">Total</vs-th>
                <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
                  <vs-td>{{ item.nama_item_pengadaan }}</vs-td>
                  <vs-td>{{ item.no_nota }}</vs-td>
                  <vs-td>{{ item.tgl_nota }}</vs-td>
                  <vs-td>
                    <div class="flex items-center space-x-2">
                      <span v-if="item.files_url.length > 0" class="text-xs underline cursor-pointer" @click="showFiles(item)">Lihat Files</span>
                      <span v-else class="text-sm">-</span>
                    </div>
                  </vs-td>
                  <vs-td class="text-right">{{ item.jml_reimburse | idr }}</vs-td>
                  <vs-td>{{ item.keterangan }}</vs-td>
                </vs-tr>
                <!--footer-->
                <vs-tr v-if="data.length > 0">
                  <vs-td></vs-td>
                  <vs-td></vs-td>
                  <vs-td></vs-td>
                  <vs-td></vs-td>
                  <vs-td class="text-right">{{ grandTotal | idr }}</vs-td>
                  <vs-td></vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </div>

      <FilesViewer :isActive.sync="modalFiles.active" :filesUrl="modalFiles.filesUrl"/>

    </vs-popup>
  </div>
</template>

<script>
import ReimburseRepository from '@/repositories/procurement/reimburse-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import FilesViewer from '@/views/components/files-viewer/FilesViewer'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'

export default {
  name: 'ReimburseView',
  props: ['isActive', 'idReimburse'],
  components: {
    FilesViewer
  },
  mounted () {
    this.getAllProyek()
  },
  watch: {
    isActive (active) {
      if (active) {
        this.getInitData()
      }
    }
  },
  data () {
    return {
      isLoading: false,
      isLoadingGetInitData: false,
      isCompressingNota: false,
      errors: null,
      data: {
        id_proyek: null,
        id_staf: null,
        details: []
      },
      modalFiles: {
        filesUrl: [],
        active: false
      },
      deletedIds: [],
      proyeks: []
    }
  },
  computed: {
    grandTotal () {
      return _.sumBy(this.data.details, item => item.jml_reimburse)
    },
    filterItemPengadaan () {
      return {
        id_proyek: this.data.id_proyek
      }
    }
  },
  methods: {
    showFiles (item) {
      this.modalFiles.filesUrl = item.files_url
      this.modalFiles.active = true
    },

    showImages (images) {
      this.$refs.viewer.show(images)
    },

    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getInitData () {
      this.isLoadingGetInitData = true

      ReimburseRepository.show(this.idReimburse)
        .then(response => {
          this.data = response.data.data
          this.data.details = _.map(this.data.details, item => {
            item.uuid = uuid()
            item.bukti_nota = null
            return item
          })
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoadingGetInitData = false
        })
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['proyeks'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      if (!isActive) {
        this.resetData()
      }
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
